// Customizable Area Start
import React from "react";

import {
    Box,
    styled,
    Typography,
    TextField,
    Button
} from "@material-ui/core"


import AppHeader from "../../../blocks/notifications/src/TopHeader.web";
import Loader from "../../../components/src/Loader.web";

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Footer from "../../../components/src/Footer.web";

import { apiKey } from "../../../components/src/asset";
import { editIcon } from "./assets";

import LocationDistancesController, {
    Props
} from "./LocationDistancesController.web";
export default class LocationDistances extends LocationDistancesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    renderCarLocationSection = () => {
        return (
            <Box>

                <Typography className="subHeaderTextLocation">Car location</Typography>

                <Box className="locationBox">
                    <Box>
                        <Typography className="mainAddressText">{this.state.catalogesAddress.address},{this.state.catalogesAddress.city},{this.state.catalogesAddress.country}, {this.state.catalogesAddress.pincode}</Typography>
                        <Typography className="subAddressText">Default pickup & return location </Typography>
                    </Box>
                    <img style={{ height: 20, width: 20, cursor: "pointer" }} src={editIcon} />
                </Box>

                <Typography className="subHeaderTextLocation">Delivery locations</Typography>

                {this.initialDeliveryLocationArray.map(item => 
                    
                    <Box className="locationBox" key={item.id}>
                    <Box style={{ display: "flex", gap: 10 }}>
                        <img src={item.icon} />
                        <Box>
                            <Typography className="mainAddressText">{item.title}</Typography>
                            <Typography className="subAddressText">{item.subTitle}</Typography>
                        </Box>
                    </Box>
                    
                    <img style={{ height: 20, width: 20, cursor: "pointer" }} src={editIcon} />
                </Box>
                )}

                <Typography className="subHeaderTextLocation">Distance Included</Typography>

                {this.state.distanceArray.map(item =>
                    <Box className="locationBox" key={item.id}>
                        <Typography className="mainAddressText">{item.title}</Typography>
                        {item.isEdit ?
                            <Box style={{ width : "10%"}}>
                                <TextField
                                    value={item.value}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleDistanceChange(item.id, event.target.value)}
                                    data-test-id="eventChange"
                                    className='textField'
                                />
                                
                            </Box>
                            :

                            <Box style={{ display: "flex", gap: 10 }}>
                                <Typography className="valText">${item.value}</Typography>
                                <img data-test-id="editImgBtn" onClick={() => this.handleDistanceClick(item.id)} style={{ height: 20, width: 20, cursor: "pointer" }} src={editIcon} />
                            </Box>
                        }



                    </Box>

                )}

                <Button className='saveBtn' onClick={this.saveDistanceAndMileage} data-test-id="saveBtn">Save</Button>

                
            </Box>
        )
    }

    renderHomeLocationSection = () => {
        return (
            <Box>
                <Box>
                    <iframe
                        width="100%"
                        height="300"
                        frameBorder="0"
                        style={{ borderRadius: 12 }}
                        referrerPolicy="no-referrer-when-downgrade"
                        src={`https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${this.state.catalogesAddress.latitude},${this.state.catalogesAddress.longitude}`}
                        allowFullScreen
                    ></iframe>
                </Box>

                <Typography className="subHeaderTextLocation">Home location</Typography>

                <Box className="locationBox">
                    <Box>
                        <Typography className="mainAddressText">{this.state.catalogesAddress.address},{this.state.catalogesAddress.city},{this.state.catalogesAddress.country}, {this.state.catalogesAddress.pincode}</Typography>
                        <Typography className="subAddressText">Default pickup & return location </Typography>
                    </Box>
                    <img style={{ height: 20, width: 20, cursor: "pointer" }} src={editIcon} />
                </Box>
            </Box>
        )
    }


    render() {
        // Customizable Area Start

        return (
            <div>
                <AppHeader activeLink="Dashboard" navigation={this.props.navigation} />
                {this.state.loading ? <Loader loading /> :

                    <CustomBox>
                        <Box className="LocationSection">
                            <Box className="backSection" onClick={this.handleBackButton} data-test-id="back-button">
                                <ArrowBackIosIcon style={{ color: "#667085", height: 16, width: 16 }} className="backIcon" />
                                <Typography className="backText">Back</Typography>
                            </Box>
                            <Box className="backSectionIcon">
                                <ArrowBackIosIcon style={{ color: "#667085", height: 16, width: 16 }} className="backIcon" />
                            </Box>

                            <Typography className="headerTextLocation">Location & Distance</Typography>

                            {this.checkCatalogeType() ? this.renderCarLocationSection() : this.renderHomeLocationSection()}
                        </Box>
                    </CustomBox>
                }

                <Footer />
            </div>);
        // Customizable Area End
    }
}
const CustomBox = styled(Box)({
    maxWidth: "1220px",
    margin: "40px auto",
    "& .LocationSection": {
        width: "75%",
        "@media(max-width:600px)": {
            width: "90%"
        }
    },
    "& .backSection": {
        display: 'flex',
        alignItems: "center",
        color: "#667085",
        gap: "6px",
        cursor : "pointer",
        "@media(max-width: 600px)": {
            display: "none"
        }
    },
    "& .backSectionIcon": {
        display: "none",
        "@media(max-width: 600px)": {
            display: "block"
        }
    },
    "& .backIcon": {
        width: "20px",
        height: "20px"
    },
    "& .backText": {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "20px",     
    },
    "& .headerTextLocation" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '30px',
        fontWeight: 600,
        color: '#101828',
        margin: "20px 0"
    },
    "& .subHeaderTextLocation" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '20px',
        fontWeight: 600,
        color: '#23395D',
        margin: "20px 0"
    },
    "& .locationBox": {
        border: "1px solid #D0D5DD",
        borderRadius : "12px",
        display : "flex",
        justifyContent : "space-between",
        padding : "25px",
        alignItems : "center",
        margin : "15px 0"
    },
    "& .mainAddressText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '18px',
        fontWeight: 600,
        color: '#23395D',
    },
    "& .subAddressText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 500,
        color: '#94A3B8',
    },
    "& .valText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 600,
        color: '#23395D',
    },
      "& .saveBtn" : {
        height: 40,
        fontSize: '14px',
        color: 'white',
        textTransform: "none",
        fontWeight: 600,
        background: "#3173E1",
        borderRadius: "12px",
        fontFamily: 'Plus Jakarta Sans',
        width: 90,
        margin: "20px 0"
      },
})

// Customizable Area End
// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import Audiovideomessenger2 from "../../blocks/audiovideomessenger2/src/Audiovideomessenger2";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import PhotoLibrary from "../../blocks/photolibrary/src/PhotoLibrary";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import ICalendarSupport from "../../blocks/icalendarsupport/src/ICalendarSupport";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Catalogueweb from "../../blocks/catalogue/src/Catalogue.web";
import ProductDescription from "../../blocks/productdescription/src/ProductDescription.web";
import ProductCheckout from "../../blocks/productdescription/src/ProductCheckout.web";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import Splitpayments2 from "../../blocks/splitpayments2/src/Splitpayments2";
import Onboardingguide from "../../blocks/onboardingguide/src/Onboardingguide";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Settings2 from "../../blocks/settings2/src/Settings2";
import FormApprovalWorkflow from "../../blocks/formapprovalworkflow/src/FormApprovalWorkflow";
import HelpCentre from "../../blocks/helpcentre/src/HelpCentre";
import HelpCentreQA from "../../blocks/helpcentre/src/HelpCentreQA";
import HelpCentreSub from "../../blocks/helpcentre/src/HelpCentreSub";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Notifications from "../../blocks/notifications/src/Notifications";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import Cfdesignconversion33 from "../../blocks/cfdesignconversion33/src/Cfdesignconversion33";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Location from "../../blocks/location/src/Location";
import Analytics from "../../blocks/analytics/src/Analytics";
import Cfdesignconversion23 from "../../blocks/cfdesignconversion23/src/Cfdesignconversion23";
import ApplePayIntegration from "../../blocks/applepayintegration/src/ApplePayIntegration";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import Cfdesignconversion6 from "../../blocks/cfdesignconversion6/src/Cfdesignconversion6";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import CameraAccess from "../../blocks/cameraaccess/src/CameraAccess";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import Inventorymanagement3 from "../../blocks/inventorymanagement3/src/Inventorymanagement3";
import CarouselDisplay from "../../blocks/carouseldisplay/src/CarouselDisplay";
import Reviews from "../../blocks/reviews/src/Reviews";
import AddReview from "../../blocks/reviews/src/AddReview";
import Digisureapiintegration3 from "../../blocks/digisureapiintegration3/src/Digisureapiintegration3";
import Rolesandpermissions2 from "../../blocks/rolesandpermissions2/src/Rolesandpermissions2";
import GoogleCalendarSync from "../../blocks/googlecalendarsync/src/GoogleCalendarSync";
import Maps from "../../blocks/maps/src/Maps";
import PricingEngine from "../../blocks/pricingengine/src/PricingEngine";
import Geofence from "../../blocks/geofence/src/Geofence";
import Chat from "../../blocks/chat/src/Chat";
import ViewChat from "../../blocks/chat/src/ViewChat";
import LinkShare from "../../blocks/linkshare/src/LinkShare";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Reservations from "../../blocks/reservations/src/Reservations";
import HostDashboard from "../../blocks/catalogue/src/HostDashboard.web"
import LogIn from "../../blocks/catalogue/src/LoginScreen.web"
import SignUp from "../../blocks/catalogue/src/SignUp.web"
import AddCarListing from "../../blocks/catalogue/src/AddCar.web"
import AddHomeListing from "../../blocks/catalogue/src/AddHome.web"
import UserProfile from "../../blocks/user-profile-basic/src/UserProfile.web"
import ReservationWeb from "../../blocks/reservations/src/Reservation.web"
import ReservationDetails from "../../blocks/reservations/src/ReservationDetails.web"
import ReservationCheckOut from "../../blocks/reservations/src/ReservationCheckout.web"
import PaymentSettings from "../../blocks/user-profile-basic/src/PaymetSettings.web"
import NotificationsSettings from "../../blocks/user-profile-basic/src/NotificationSettings.web"
import SupportCenterSettings from "../../blocks/user-profile-basic/src/SupportCenter.web"
import TicketCenterSettings from "../../blocks/user-profile-basic/src/TicketSupport.web"
import Faqs from "../../blocks/user-profile-basic/src/Faqs.web"
import HostReservation from "../../blocks/catalogue/src/HostReservations.web"
import HostReservationDetail from "../../blocks/catalogue/src/HostReservationDetail.web"
import HostReservationCheckout from "../../blocks/catalogue/src/HostReservationCheckout.web"
import BookingHistory from "../../blocks/catalogue/src/BookingHistory.web"
import ReportIssue from "../../blocks/catalogue/src/ReportIssue.web"
import InstructionPage from "../../blocks/catalogue/src/InstuctionPage.web"
import VerifyOtp from "../../blocks/productdescription/src/VerifyOtp.web"
import FinalCheckout from "../../blocks/reservations/src/FinalCheckoutReservation.web"
import HostSettings from "../../blocks/catalogue/src/HostSettings.web"
import ListingSettings from "../../blocks/catalogue/src/ListingSettings.web"
import LocationDistances from '../../blocks/catalogue/src/LocationDistances.web'
import ProtectionPlans from "../../blocks/catalogue/src/ProtectionPlan.web"
import TripHistory from "../../blocks/catalogue/src/TripHistory.web"
import GuestInstructions from  "../../blocks/catalogue/src/GuestInstruction.web"
import ExtraAddOn from '../../blocks/catalogue/src/ExtraAddOn.web'
import TripPreference from '../../blocks/catalogue/src/TripPreference.web'
import HostPostTrip from "../../blocks/catalogue/src/HostPostTrip.web"
import ApprovalStatus from "../../blocks/user-profile-basic/src/ApprovalStatus.web";
import PostRenterHome from "../../blocks/reservations/src/PostHomeRenter.web";
import PreTripFlowRenter from "../../blocks/reservations/src/PreTripReservationFlow.web"
import PreTripFlowCheckout from "../../blocks/reservations/src/PreTripCheckout.web"
import PostTripFlowRenter from "../../blocks/reservations/src/PostTripReservationFlow.web"
import PostTripFlowCheckout from "../../blocks/reservations/src/PostTripCheckoutFlow.web"
import PostReview from "../../blocks/reservations/src/ReviewScreen.web"
import TripDetails from "../../blocks/catalogue/src/TripCompleted.web"
import RenterIssues from "../../blocks/reservations/src/RenterIssues.web"


import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file



const routeMap = {
  LogIn:{
    component:LogIn,
    path:"/login"
},
VerifyOtp:{
  component:VerifyOtp,
  path:"/verification"
},
ProtectionPlans:{
  component:ProtectionPlans,
  path:"/protectionPlan/:subId"
},
TripHistory:{
  component:TripHistory,
  path:"/tripHistory/:subId"
},
GuestInstructions:{
  component:GuestInstructions,
  path:"/guestInstructions/:subId"
},
ListingSettings : {
  component:ListingSettings,
  path:"/catalogueSettings/:catalogueId/:catalogueType"
},
PostRenterHome : {
  component:PostRenterHome,
  path:"/postRenterHome"
},
ReportIssue:{
  component:ReportIssue,
  path:"/reportIssue/:bookingId"
},
RenterIssues : {
  component:RenterIssues,
  path:"/renterReportIssue/:bookSlotId"
},
TripDetails:{
  component:TripDetails,
  path:"/tripInformation/:bookSlotId"
},
BookingHistory:{
  component:BookingHistory,
  path:"/bookingHistory"
},
HostSettings:{
  component:HostSettings,
  path:"/hostSettings"
},
LocationDistances:{
  component:LocationDistances,
  path:"/locationDistances/:subId"
},
TripPreference:{
  component:TripPreference,
  path:"/tripPreference/:subId"
},
ExtraAddOn:{
  component:ExtraAddOn,
  path:"/extraAddOn/:subId"
},
HostReservation:{
  component:HostReservation,
  path:"/hostReservation"
},
HostReservationDetail:{
  component:HostReservationDetail,
  path:"/hostReservationDetails/:reservationId"
},
HostPostTrip:{
  component:HostPostTrip,
  path:"/hostPostTripDetails/:reservationId"
},
HostReservationCheckout:{
  component:HostReservationCheckout,
  path:"/hostReservationCheckout/:reservationId"
},
InstructionPage:{
  component:InstructionPage,
  path:"/hostInstructionPage/:reservationId"
},
FinalCheckout:{
  component:FinalCheckout,
  path:"/finalCheckout/:reservationId"
},
Reservation:{
  component:ReservationWeb,
  path:"/Reservation"
},
ReservationDetails:{
  component:ReservationDetails,
  path:"/ReservationInfo/:actualId"
},
PreTripFlowRenter:{
  component:PreTripFlowRenter,
  path:"/preTripFlow/:bookSlotId"
},
PreTripFlowCheckout:{
  component:PreTripFlowCheckout,
  path:"/preTripFlowCheckout/:bookSlotId"
},
PostTripFlowRenter:{
  component:PostTripFlowRenter,
  path:"/postTripFlow/:bookSlotId"
},
PostTripFlowCheckout:{
  component:PostTripFlowCheckout,
  path:"/postTripFlowCheckout/:bookSlotId/:bookSlotType"
},
PostReview:{
  component:PostReview,
  path:"/postReview/:bookSlotId"
},
ReservationCheckOut:{
  component:ReservationCheckOut,
  path:"/Checkout/:reservationId"
},
SignUp:{
  component:SignUp,
  path:"/signup"
},
UserProfile:{
  component:UserProfile,
  path:"/profile"
},
PaymentSettings:{
  component:PaymentSettings,
  path:"/paymentInfo"
},
NotificationsSettings:{
  component:NotificationsSettings,
  path:"/notificationsInfo"
},
ApprovalStatus:{
  component:ApprovalStatus,
  path:"/approvalStatusInfo"
},
SupportCenterSettings:{
  component:SupportCenterSettings,
  path:"/supportCenter"
},
TicketCenterSettings:{
  component:TicketCenterSettings,
  path:"/ticketCenter"
},
Faqs:{
  component:Faqs,
  path:"/faqs"
},
HostDashboard:{
    component:HostDashboard,
    path:"/Dashboard"
},
AddCarListing:{
  component:AddCarListing,
  path:"/addCarListing"
},
AddHomeListing:{
  component:AddHomeListing,
  path:"/addHomeListing"
},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
Audiovideomessenger2:{
 component:Audiovideomessenger2,
path:"/Audiovideomessenger2"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
PhotoLibrary:{
 component:PhotoLibrary,
path:"/PhotoLibrary"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
ICalendarSupport:{
 component:ICalendarSupport,
path:"/ICalendarSupport"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
Catalogueweb:{
  component:Catalogueweb,
 path:"/Catalogueweb"},
 ProductDescription:{
  component:ProductDescription,
 path:"/ProductDescription/:id/:type"},
 ProductCheckout:{
  component:ProductCheckout,
 path:"/ProductCheckout/:id/:type"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
Splitpayments2:{
 component:Splitpayments2,
path:"/Splitpayments2"},
Onboardingguide:{
 component:Onboardingguide,
path:"/Onboardingguide"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
FormApprovalWorkflow:{
 component:FormApprovalWorkflow,
path:"/FormApprovalWorkflow"},
HelpCentre:{
 component:HelpCentre,
path:"/HelpCentre"},
HelpCentreQA:{
 component:HelpCentreQA,
path:"/HelpCentreQA"},
HelpCentreSub:{
 component:HelpCentreSub,
path:"/HelpCentreSub"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
Cfdesignconversion33:{
 component:Cfdesignconversion33,
path:"/Cfdesignconversion33"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Location:{
 component:Location,
path:"/Location"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
Cfdesignconversion23:{
 component:Cfdesignconversion23,
path:"/Cfdesignconversion23"},
ApplePayIntegration:{
 component:ApplePayIntegration,
path:"/ApplePayIntegration"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
Cfdesignconversion6:{
 component:Cfdesignconversion6,
path:"/Cfdesignconversion6"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
CameraAccess:{
 component:CameraAccess,
path:"/CameraAccess"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
Inventorymanagement3:{
 component:Inventorymanagement3,
path:"/Inventorymanagement3"},
CarouselDisplay:{
 component:CarouselDisplay,
path:"/CarouselDisplay"},
Reviews:{
 component:Reviews,
path:"/Reviews"},
AddReview:{
 component:AddReview,
path:"/AddReview"},
Digisureapiintegration3:{
 component:Digisureapiintegration3,
path:"/Digisureapiintegration3"},
Rolesandpermissions2:{
 component:Rolesandpermissions2,
path:"/Rolesandpermissions2"},
GoogleCalendarSync:{
 component:GoogleCalendarSync,
path:"/GoogleCalendarSync"},
Maps:{
 component:Maps,
path:"/Maps"},
PricingEngine:{
 component:PricingEngine,
path:"/PricingEngine"},
Geofence:{
 component:Geofence,
path:"/Geofence"},
Chat:{
 component:Chat,
path:"/Chat"},
ViewChat:{
 component:ViewChat,
path:"/ViewChat"},
LinkShare:{
 component:LinkShare,
path:"/LinkShare"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
OrderManagement:{
 component:OrderManagement,
path:"/OrderManagement"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Reservations:{
 component:Reservations,
path:"/Reservations"},

  Home: {
component:Catalogueweb,
    path: '/',
    exact:true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  // AlertWeb: {
  //   component: AlertBlock,
  //   path: "*/AlertWeb",
  //   modal: true
  // }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh' }}>
        <TopNav />
        <ToastContainer />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;

// Customizable Area Start
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, {
  getName
} from "framework/src/Messages/MessageEnum";
import { apiCalling, truthyValue } from "../../../components/src/asset";
import { AddressInfo, Distance, DeliveryLocation } from "../../../components/src/TypeInterfaces.web";
import { flightIcon } from "./assets";
import { toast } from "react-toastify"

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start 
  loading : boolean, 
  catalogueId: string,
  catalougeType : string,
  catalogesAddress : AddressInfo,
  distanceArray : Distance[]
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LocationDistancesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getLocationInfoApiCallId: string = "";
  saveLocationInfoApiCallId: string = "";
  getCatalogesAddressInfoApiCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      catalogueId: "",
      catalougeType : "",
      loading : true,
      catalogesAddress : this.initialAddress,
      distanceArray : this.initialDistanceArray
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestCallId) {
         case this.getCatalogesAddressInfoApiCallId : 
            return this.handleGetCatalogeAddressResponse(responseJson)
          case this.saveLocationInfoApiCallId :
            return this.handleSaveResponse(responseJson)
          default :
             break;
      }
       
    }

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const catalougeId = this.props.navigation.getParam("subId")
    this.setState({ catalogueId: catalougeId })
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if(prevState.catalogueId !== this.state.catalogueId) {
       this.getCatalogeAddressData()
    }
  }

  handleGetCatalogeAddressResponse = (responseJson : any) => {
    if (responseJson && responseJson.catalogue && responseJson.catalogue.data) {
      const responseObject = responseJson.catalogue.data.attributes
      const addressObject = truthyValue(responseObject.address)

      const savedAddress = {
        addressble_id: truthyValue(addressObject.addressble_id),
        country: truthyValue(addressObject.country),
        latitude: truthyValue(addressObject.latitude),
        longitude: truthyValue(addressObject.longitude),
        address: truthyValue(addressObject.address),
        city: truthyValue(addressObject.city),
        pincode: addressObject.pincode !== null ? truthyValue(addressObject.pincode) : "",
        price: truthyValue(addressObject.price),
      }

      const modifiedArray: Distance[] = [
        {
          id: 1,
          title: "Miles included per day",
          value: this.handleEmptValue(truthyValue(responseObject.miles_per_day)),
          isEdit: false,
          keyName: "miles_per_day"
        },
        {
          id: 2,
          title: "Additional distance fee",
          value: this.handleEmptValue(truthyValue(responseObject.addtional_miles_charge)),
          isEdit: false,
          keyName: "addtional_miles_charge",
        },
        {
          id: 3,
          title: "Unlimited mileage fee",
          value: this.handleEmptValue(truthyValue(responseObject.unlimited_miles)) ,
          isEdit: false,
          keyName: "unlimited_miles"
        }
      ]

      this.setState({
         catalougeType : truthyValue(responseObject.category),
         catalogesAddress : savedAddress,
         distanceArray : modifiedArray,
         loading : false
      })


    } else {
      this.setState({ loading: false })
    }
  }

  handleSaveResponse = (responseJson : any) => {
    if(responseJson && responseJson.message) {
       toast.success(responseJson.message)
       this.getCatalogeAddressData()
    } else {
       this.setState({ loading : false})
       toast.error("Something went wrong, Please try again later")
    }
  }

  getCatalogeAddressData = () => {
    const headerWeb = {
      "token": localStorage.getItem("userToken")
    };
    
    this.getCatalogesAddressInfoApiCallId = apiCalling({
      header: JSON.stringify(headerWeb),
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.getCatalougeInfoApiEndpoint}/${this.state.catalogueId}`,
    })
  }

  saveDistanceAndMileage = () => {
    this.setState({ loading : true })

    const headerWeb = {
      "token": localStorage.getItem("userToken")
    };

    const formData =  new FormData()

    formData.append("catalogue[miles_per_day]", this.findFromKeyName("miles_per_day"))
    formData.append("catalogue[addtional_miles_charge]", this.findFromKeyName("addtional_miles_charge"))
    formData.append("catalogue[unlimited_miles]", this.findFromKeyName("unlimited_miles"))

    
    this.saveLocationInfoApiCallId = apiCalling({
      header: JSON.stringify(headerWeb),
      method: configJSON.apiMethodTypePut,
      endPoint: `${configJSON.saveDistanceIncludedApiEndPoint}/${this.state.catalogueId}`,
      body : formData
    })
  }

  initialAddress : AddressInfo = {
    addressble_id: "",
    country: "",
    latitude: 0,
    longitude: 0,
    address: "",
    city: "",
    pincode: 0,
    price: 0,
  }

  initialDistanceArray : Distance[] = [
    {
      id : 1,
      title : "Miles included per day",
      value : 250,
      isEdit : false,
      keyName : "miles_per_day"
    },
    {
      id : 2,
      title : "Additional distance fee",
      value : 2.50,
      isEdit : false,
      keyName : "addtional_miles_charge",
    },
    {
      id : 3,
      title : "Unlimited mileage fee",
      value : 3.50,
      isEdit : false,
      keyName : "unlimited_miles"
    }
  ]

  initialDeliveryLocationArray : DeliveryLocation[] = [
    {
      id : 1,
      title : "LAX International Airport",
      subTitle : "$50 each way",
      icon : flightIcon
    },
    {
      id : 2,
      title : "LA Union Station",
      subTitle : "$20 each way",
      icon : flightIcon
    },
    {
      id : 3,
      title : "Intercontinental Hotel, 90017",
      subTitle : "$45 each way",
      icon : flightIcon
    },
  ]

  handleDistanceClick = (distanceId : string | number) => {
      
    const modifiedArray = this.state.distanceArray.map(item => 
      item.id === distanceId ? { ...item, isEdit: !item.isEdit } : item
    );
    
    this.setState({ distanceArray : modifiedArray})
  }
  
  handleDistanceChange = (itemId: string | number, inputValue: string) => {

    const parsedValue = inputValue === "" ? 0 : parseInt(inputValue);

    this.setState(prevState => ({
      distanceArray: prevState.distanceArray.map(distance =>
        distance.id === itemId
          ? { ...distance, value: parsedValue }
          : distance
      )
    }));
  };

  findFromKeyName = (keyName : string) => {
     const value = this.state.distanceArray.filter(item => item.keyName === keyName).map(item => item.value)
     return value.toString()
  }

  handleBackButton = () => {
    this.props.navigation.goBack()
  }

  checkCatalogeType = () => {
    return this.state.catalougeType === "Car" ? true : false
  }

  handleEmptValue = (value : string) => {
    return value !== null ? parseFloat(value) : 0
  }
  // Customizable Area End
}

// Customizable Area End
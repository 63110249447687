// Customizable Area Start
import React from "react";

import PostTripCommonSidebar from "../../../components/src/PostTripCommonSidebar.web"

import {
    Box,
    Grid,
    Typography,
    styled,
    Button,
    Dialog,
    IconButton,
    TextareaAutosize
} from "@material-ui/core"

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import CloseIcon from '@material-ui/icons/Close';
import { shapeIcon1, shapeIcon2 } from "../../../components/src/asset";
import { deleteIcon } from "../../../blocks/catalogue/src/assets";


import RenterIssuesController, {
    Props,
  } from "./RenterIssuesController";


class RenterIssues extends RenterIssuesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  renderUploadSection = () => {
    return (
      <Box style={{ marginTop : 30}}>
          <Typography className="photoTitle">Add Photos</Typography>
          <Typography className="photoSubTitle">If report requires pictures add them.</Typography>

        <Box>
          {this.state.uploadedIsuesPhotos.length > 0 ?
            <Box style={{ margin: "25px 0" }}>
              <Grid container spacing={3}>
                {this.state.uploadedIsuesPhotos.map((url, index) => (
                  <Grid item md={4} sm={6} xs={12} style={{ position: "relative" }} key={index}>
                    <img data-test-id="deleteUploadedPhoto" onClick={() => this.handleDeleteImages(index)} style={{ position: "absolute", top: 30, right: 30, cursor: "pointer" }} src={deleteIcon} alt="deleteIcon" />
                    <img key={index} src={url} alt={`preview-${index}`} style={{ height: '130px', width: "100%", borderRadius: 16 }} />
                  </Grid>
                ))}
              </Grid>

              <Box style={{ padding: 10, border: "1px dashed #3173E1", borderRadius: 12, textAlign: 'center', margin: "10px 0" }}>
                <Typography className="dragDropText"> Add More <span data-test-id="addMoreText" onClick={() => this.handleRefsCall()} style={{ color: "#3173E1", textDecoration: "underline", cursor: "pointer" }}>Photo(s)</span> </Typography> </Box>
              <input type="file" data-test-id={"addMorePhotos"} onChange={this.handleImagesChange} ref={this.imageRefs} multiple style={{ display: "none" }} />

            </Box>
            :
            <Box className="uploadBox">
              <Box style={{ display: "flex", flexDirection: "column", margin: "auto" }}>
                <Typography className="dragDropText">Drag and drop or  <span style={{ color: "#3173E1", textDecoration: "underline", cursor: "pointer", fontWeight: 600 }} data-test-id="uplodPhotos" onClick={() => this.handleRefsCall()}> browse </span> files</Typography>
                <input type="file" data-test-id={"photosChange"} onChange={this.handleImagesChange} ref={this.imageRefs} multiple style={{ display: "none" }} />
              </Box>
            </Box>
          }
        </Box>

        {this.state.uploadedIsuesPhotos.length === 0 && <Typography className="noteText"><li> Images ( .jpg,.gif, .png, up to 10 MB, no more than 40000 px in any dimension) </li></Typography>}
      </Box>
    )
  }

  renderFirstStep = () => {
    return (
      <Box>
        <Box style={{ marginBottom: 30 }}>
          <Typography className="issueText">Select Issue</Typography>
          <Typography className="issueSubText">Share your experience! Your feedback helps our community grow and ensures a better stay for future guests.</Typography>
        </Box>

        <Box>
          <Grid container spacing={3}>
            {this.state.reportsCatogories.map(item =>
              <Grid item md={6} key={item.reportId}>
                <Box data-test-id="slectReportIssue" onClick={() => this.handleSelctedIssue(item.reportType)} className={this.state.selectedIssue === item.reportType ? "activeCard" : "mainCard"}>
                  <Box>
                    <Typography className="cardTitle">{item.reportTitle}</Typography>
                    <Typography className="cardSubTitle">{item.reportSubTitle}</Typography>
                  </Box>
                  <ArrowForwardIosIcon className="cardIcon" />
                </Box>
              </Grid>
            )}

          </Grid>
        </Box>

        <Box style={{ display: "flex", gap: 20, margin: "20px 0" }}>
          <Button data-test-id="backBtn" onClick={this.navToBackButton} className="backPage">Back</Button>
          <Button data-test-id="nextBtn" onClick={this.onContinueClick} className="nextPage">Continue</Button>
        </Box>
      </Box>
    )
  }

  renderSecondStep = () => {
    return (
      <Box>
        <Box style={{ marginBottom: 30 }}>
          <Typography className="issueText">Describe the Issue</Typography>
        </Box>

        <Box>
          <Box style={{ display: "flex", gap: 8, alignItems: "center" }}>
            <Typography className="headerStep2Text">Description </Typography>
            <HelpOutlineIcon style={{ height: 20, width: 20, color : "#23395D" }} />
          </Box>
          <TextareaAutosize
            placeholder="Tell guests what makes your car unique and why they’ll love driving it. New listings with descriptions of least 100 words are up to 3x more likely to get booked."
            className="issueDescription"
            data-test-id="description"
            value={this.state.issueDescription}
            onChange={this.handleChangeForIssueDescription}
          />
        </Box>

        {this.renderUploadSection()}


        <Box style={{ display: "flex", gap: 20, margin: "20px 0" }}>
          <Button data-test-id="backBtnStep2" onClick={this.backToStepOne} className="backPage">Back</Button>
          <Button data-test-id="nextBtnStep2" onClick={this.openThanksModal} className="nextPage">Submit</Button>
        </Box>
      </Box>
    )
  }

  render() {
    // Customizable Area Start
    
    return (
      <div>
          <PostTripCommonSidebar navigation={this.props.navigation} id="renterIssue" pageTitle="renterIssue">
            <ReportIssueWrapper>

              {this.state.isIssueSelected ? this.renderSecondStep() : this.renderFirstStep()}

              
                
            </ReportIssueWrapper>
         </PostTripCommonSidebar>

          <ModalWrapper
            open={this.state.thanksModal}
            onClose={this.closeThanksModal}
            fullWidth
            data-test-id="closeModal"
            maxWidth="sm"

          >
            <Box>

              <Box style={{ textAlign: "center", marginTop: "20px", position: "relative" }}>
              <Box className="imageContainer">
                <img src={shapeIcon1} alt="shape1" className="backgroundShape" />
                <img src={shapeIcon2} alt="shape2" className="foregroundShape" />
              </Box>
                <IconButton style={{ marginRight: 20, position: "absolute", right: 10, top: 0 }} edge="end" color="inherit" data-test-id="closeIcon" onClick={this.closeThanksModal} aria-label="close">
                  <CloseIcon style={{ color: "white" }} />
                </IconButton>
              </Box>

              <Box textAlign={"center"} style={{ margin: "35px 0" }}>
                <Typography className="headerModal">Thank you for reporting the issue! </Typography>
                <Typography className="subHeaderModal">
                  Your feedback is important to us. Our support
                </Typography>
                <Typography className="subHeaderModal">
                  team has received your report and will investigate
                </Typography>
                <Typography className="subHeaderModal">
                  it promptly.
                </Typography>
                <Button className="finishBtn" data-test-id="navToCheckout">FINISH</Button>
              </Box>
            </Box>
          </ModalWrapper>
      </div>
    );
    // Customizable Area End
  }
}

export default RenterIssues;

const ReportIssueWrapper = styled(Box)({
  "& .issueText": {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '20px',
    fontWeight: 600,
    color: '#23395D'
  },
  "& .issueSubText": {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    fontWeight: 400,
    color: '#475467'
  },
  "& .mainCard" : {
    border: "1px solid #E7E7E7",
    boxShadow: "0px 4px 5px 0px #0000000D",
    borderRadius: 12,
    padding : "12px 14px",
    display : "flex",
    justifyContent : "space-between",
    height : "145px",
    cursor : "pointer"
  },
  "& .activeCard" : {
    boxShadow: "none",
    borderRadius: "12px",
    border: "1px solid #0F73EE",
    background: "#EEF2FA",
    padding : "12px 14px",
    display : "flex",
    justifyContent : "space-between",
    height : "145px",
    cursor : "pointer"
},
  "& .cardTitle" : {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '16px',
    fontWeight: 600,
    color: '#23395D',
    marginBottom : "4px"
  },
  "& .cardIcon" : {
    height : 26, 
    width: 26, 
    color : "#23395D"
  },
  "& .cardSubTitle" : {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    fontWeight: 500,
    color: '#23395D',
    maxWidth : 300
  },
  "& .backPage" : {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    fontWeight: 600,
    color: '#475467',
    height: '48px',
    borderRadius: '12px',
    width: '150px',
    backgroundColor: 'white',
    padding: '14px 16px',
    margin: "15px 0",
    textTransform: "none",
    gap: '8px',
    border: "1px solid #D0D5DD", 
  },
  "& .nextPage" : {
    width: '150px',
    textTransform: "none",
    gap: '8px',
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    fontWeight: 600,
    color: 'white',
    backgroundColor: '#3173E1',
    border: "1px solid #D0D5DD",
    margin: "15px 0",
    height: '48px',
    borderRadius: '12px',
    padding: '14px 16px',
  },
  "& .issueDescription": {
    padding: "20px",
    height: '200px !important',
    gap: '8px',
    borderRadius: '12px',
    border: "1px solid #D0D5DD",
    width : "95%"
  },
  "& .headerStep2Text" : {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '16px',
    fontWeight: 600,
    color: '#23395D'
  },
  "& .photoTitle":{
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '16px',
    fontWeight: 600,
    color: '#23395D'
  },
  "& .photoSubTitle":{
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    fontWeight: 500,
    color: '#5B616B'
  },
  "& .uploadBox" : {
    height: "250px",
    border: "1px dashed #3173E1",
    borderRadius: "12px",
    display: "flex",
    margin: "20px 0",
    width : "95%"
  },
  "& .dragDropText" : {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '16px',
    fontWeight: 600,
    color: '#23395D'
  },
  "& .noteText" : {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '16px',
    fontWeight: 500,
    color: '#23395D'
  }
})

const ModalWrapper = styled(Dialog)({
  "& .MuiDialog-paper" : {
    background : "#3173E1",
    borderRadius : "16px !important"
  },
  "& .headerModal": {
    color: "white",
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '24px',
    fontWeight: 600,
    marginBottom : "10px"
  },
  "& .subHeaderModal": {
    color: "#FFFFFF99",
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '16px',
    fontWeight: 500,
  },
  "& .finishBtn": {
    height: "40px",
    fontFamily: 'Plus Jakarta Sans',
    marginTop: "30px",
    fontSize: '14px',
    fontWeight: 600,
    textTransform: "none",
    borderRadius: 30,
    textAlign: 'left',
    color: "#3173E1",
    background: "White",
    width: "200px"
  },
  "& .imageContainer" : {
    position: "relative",
    width: "200px",
    height: "200px",
  }, 
  "& .backgroundShape" : {
    position: "absolute",
    top: 0,
    left: "70%",
    zIndex: 1,
  },
  "& .foregroundShape" : {
    position: "absolute",
    top: "14%",
    left: "90%",
    zIndex: 2,
  }
})

// Customizable Area End
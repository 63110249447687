import MessageEnum, {
    getName
} from "../../framework/src/Messages/MessageEnum";
import { Message } from "../../framework/src/Message";
import { runEngine } from "../../framework/src/RunEngine";
import { Country }  from 'country-state-city';
import swal from "sweetalert";


interface ApiParams {
    header: string 
    endPoint: string
    method: string 
    body?: string | FormData| unknown;
}

export interface SearchCriteria {
    price?: {
      from: number;
      to: number;
    };
    date?: {
      from: string;
      to: string;
    };
    catogory_id: number;
    feature?: string[];
    features?: {
      Guests?: number;
      bathrooms?: number;
      Bedrooms?: number;
    };
    brand_id?: number[];
    rating?: number;
}

export const checkOutImageIcon = require("./assets/checkOutImage.png")
export const carCheckOutImageIcon = require("./assets/carCheckout.png")
export const shapeIcon1 = require("./assets/shape1.png")
export const shapeIcon2 = require("./assets/shape2.png")
export const notificationIcon1 = require("./assets/notification1.png")
export const notificationIcon2 = require("./assets/notification2.png")
export const notificationIcon3 = require("./assets/notification3.png")
export const notificationIcon4 = require("./assets/notification4.png")
export const starIcon = require("./assets/starIcon.svg")
export const skipIcon = require("./assets/skipIcon.svg")
export const defaultProfileIcon = require("./assets/defaultProfileIcon.png")
export const carScheduleIcon = require("./assets/carIcon.svg")
export const homeScheduleIcon = require("./assets/homeIcon.svg")
export const apiKey = "AIzaSyCwwkcI-XMWref6Pp2BSEhLCsgGJgY0Jsc";



export const apiCalling = ({ header, endPoint, method, body } : ApiParams) => {
    const requestMessage = new Message( getName(MessageEnum.RestAPIRequestMessage));
    requestMessage.addData( getName(MessageEnum.RestAPIResponceEndPointMessage),endPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),header);
    body && requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage),body);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),method);
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId
}

export const truthyBedValueValue = (data : any) => {
    return data.attributes.features[2]?.value
}

export const truthyToiletValueValue = (data : any) => {
    return data.attributes.features[3]?.value
}

export function generateRandomNumberPlate() {
    const stateCodes : { [key: string]: string } = {
        "Andhra Pradesh": "ap",
        "Arunachal Pradesh": "ar",
        "Assam": "as",
        "Bihar": "br",
        "Chhattisgarh": "cg",
        "Goa": "ga",
        "Gujarat": "gj",
        "Haryana": "hr",
        "Himachal Pradesh": "hp",
        "Jammu and Kashmir": "jk",
        "Jharkhand": "jh",
        "Karnataka": "ka",
        "Kerala": "kl",
        "Madhya Pradesh": "mp",
        "Maharashtra": "mh",
        "Manipur": "mn",
        "Meghalaya": "ml",
        "Mizoram": "mz",
        "Nagaland": "nl",
        "Odisha": "or",
        "Punjab": "pb",
        "Rajasthan": "rj",
        "Sikkim": "sk",
        "Tamil Nadu": "tn",
        "Telangana": "ts",
        "Tripura": "tr",
        "Uttar Pradesh": "up",
        "Uttarakhand": "uk",
        "West Bengal": "wb",
        "Andaman and Nicobar Islands": "an",
        "Chandigarh": "ch",
        "Dadra and Nagar Haveli and Daman and Diu": "dd",
        "Lakshadweep": "ld",
        "Delhi": "dl",
        "Puducherry": "py"
    };

    const states = Object.keys(stateCodes);
    const randomState  = states[Math.floor(Math.random() * states.length)];

    const stateCode = stateCodes[randomState];

    const districtCode = Math.floor(Math.random() * 99) + 1;

    const charCode = String.fromCharCode(97 + Math.floor(Math.random() * 26)) + String.fromCharCode(97 + Math.floor(Math.random() * 26));

    const number = Math.floor(Math.random() * 9000) + 1000;

    const numberPlate = stateCode + districtCode.toString().padStart(2, '0') + charCode + number;

    return numberPlate
}

export const truthyValue = (key: any) => {
    if (key !== "" || key !== null || !key.length) {
        return key
    } else {
        return ""
    }     
}

export const getDefaultCountryCodes = () => {
    const Countries = Country.getCountryByCode("IN")  
    return Countries
}

export const getAllCountries = () => {
    const AllCountries = Country.getAllCountries()
    return AllCountries
}

export const userAuthDisplayPopup = () => {
    if(!localStorage.getItem("userToken")) {
        swal("Please Login/Signup to continue","", "error")
        return false
      }
    return true
}

export const getUserRole = () => {

    return localStorage.getItem("userRole")
}

export const checkIfLogged = () => {
    if(!localStorage.getItem("userToken")) {
        return false;
    }
    return true;
};

export const refFunction = (msgRef: React.RefObject<HTMLInputElement>) => {
    if (msgRef.current) {
        msgRef.current.scrollIntoView({ behavior: 'smooth' });
    } 
}

const urlToFile = async (url: string, filename: string, mimeType: string): Promise<any> => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new File([blob], filename, { type: mimeType });
}

export const convertUrlsToFiles = async (images: any): Promise<File[]> => {
    const promises = images.map(async (image: any) => {
        const urlParts = image.url.split('/');
        const filename = urlParts[urlParts.length - 1];
        const mimeType = 'image/png';
        return urlToFile(image.url, filename, mimeType);
    });

    return Promise.all(promises);
}

export const getCountryName = (location : string) => {
    let stringVal = location.split(',');

    if (stringVal && stringVal.length > 0) {
        const lastElement = stringVal.pop() || ''; 
        return lastElement.trim();
    } else {
        return location.toString();
    }
}
